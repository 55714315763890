import * as React from "react"
import "./index.scss"

const AnimationBorder = ({ children }) => {
  const [animate, setAnimate] = React.useState(false)

  setTimeout(() => {
    setAnimate(true)
  }, 6000)

  return (
    <div className={animate ? "animation-border-card" : ""}>{children}</div>
  )
}

export default AnimationBorder
