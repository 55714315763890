import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import ShopPage from "../components/shopPage"
import { Button, Col, Form, Row } from "react-bootstrap"
import { navigate, graphql, Link } from "gatsby"
import _ from "lodash"
import useCart from "../hooks/useCart"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowUp,
  faInfo,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons"
import PriceIncrease from "../components/modals/priceIncrease"
import AnimationBorder from "../components/AnimationBorder"

/** @todo: finish this dynamic template */
const Product = ({ data }) => {
  const [selectedVariant, setSelectedVariant] = useState(
    data?.products.variants[0].sku
  )
  const [modalShow, setModalShow] = React.useState(false)
  const { addToCart, getPrice } = useCart()

  useEffect(() => {
    setSelectedVariant(data?.products.variants[0].sku)

    try {
      let zPrice = ""
      let zName = ""

      if (document.querySelector("p.h5.mb-0.bank-font") != null) {
        zPrice = document
          .querySelector("p.h5.mb-0.bank-font")
          .innerText.replace("$", "")
      }

      if (document.querySelector("h1.mb-0.bank-font") != null) {
        zName = document.querySelector("h1.mb-0.bank-font").innerText
      }

      window.zonos.quote({
        items: [
          {
            name: zName,
            price: zPrice,
          },
        ],
        currencyCode: "USD",
      })
    } catch (error) {}
  }, [])

  const addItemToCart = () => {
    addToCart(selectedVariant)
    navigate("/cart")
  }

  const getItemData = () => {
    return data?.products.variants.findIndex(
      product => product.sku === selectedVariant
    )
  }

  const isItAfterThe10th = () => {
    // check date only show before 10th
    return new Date("12/10/2023").getTime() < new Date().getTime()
  }

  const openModal = e => {
    e.preventDefault()
    e.stopPropagation()
    setModalShow(true)
  }

  return (
    <Layout>
      <Seo title={data?.products?.title} />
      <section className="py-5 container">
        <Row className="text-white">
          <Col md="6">
            <ShopPage product={data?.products.variants[0].sku} />
          </Col>
          <Col md="6" className="d-flex flex-column">
            <div>
              <div className="d-flex align-items-baseline justify-content-between mt-4 mt-md-0">
                {data?.products?.hasInfoModal && !isItAfterThe10th() ? (
                  <div className="d-flex align-items-center">
                    <h1 className="mb-0 bank-font">{data?.products?.label}</h1>
                    <a
                      href="#"
                      target="_self"
                      className="mt-1"
                      onClick={openModal}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="text-warning ms-2"
                      />
                    </a>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-start">
                    <h1 className="mb-0 bank-font">{data?.products?.label}</h1>
                    {data?.products?.limitedEdition && (
                      <sup
                        className="text-danger fw-bold ms-1 mt-2 text-uppercase"
                        style={{ fontSize: "20px" }}
                      >
                        Limited
                      </sup>
                    )}
                  </div>
                )}
                <p className="h5 mb-0 bank-font">
                  ${getPrice(data?.products?.variants[getItemData()].cost)}
                </p>
              </div>
              <hr className="w-100" />
              <Row className="d-flex d-md-none align-items-center">
                <Col
                  md="12"
                  className="d-flex justify-content-between align-items-center"
                >
                  <Form.Label
                    className="minimal-title pe-3 mb-0"
                    htmlFor="group1"
                  >
                    Select Size
                  </Form.Label>
                  {data?.products?.hasAGuide && (
                    <Link to="/sizing-guide" className="text-white">
                      Sizing Guide
                    </Link>
                  )}
                </Col>
                <Col className="col-7">
                  <Form.Group controlId="group1">
                    {data?.products?.variants?.map((product, index) => (
                      <Form.Check
                        key={index}
                        inline
                        label={product.name}
                        name="group1"
                        type="radio"
                        id={`inline-${index}`}
                        className={`btn btn-outline-light text-uppercase ${
                          data?.products?.isARingProduct
                            ? "rings-selection"
                            : "rings-selection"
                        } ${selectedVariant === product.sku ? "active" : ""}`}
                        checked={selectedVariant === product.sku}
                        onChange={() => setSelectedVariant(product.sku)}
                      />
                    ))}
                  </Form.Group>
                </Col>
                <Col className="col-5">
                  <AnimationBorder>
                    <Button
                      className="btn btn-outline-light rounded gold-text w-100 "
                      onClick={addItemToCart}
                    >
                      Add To Cart
                    </Button>
                  </AnimationBorder>
                </Col>
              </Row>
              <hr className="w-100 d-block d-md-none" />
              <p className="minimal-title">PRODUCT DETAILS</p>
              <p
                className="minimal-body"
                dangerouslySetInnerHTML={{
                  __html: data?.products?.description,
                }}
              ></p>
              <ul className="minimal-body">
                {_.map(
                  [
                    ...data?.products?.variants[getItemData()].features,
                    ...data?.products?.features,
                  ],
                  (value, key) => (
                    <li key={key}>{value}</li>
                  )
                )}
              </ul>
              <p className="minimal-title mb-0 mt-5">CUSTOMER SERVICE</p>
              <small>
                We are pleased to assist you at any time via{" "}
                <a
                  href="mailto:shlama@tawalap.com"
                  className="text-decoration-none text-white"
                >
                  email
                </a>
                .
              </small>
            </div>
            <Row className="align-items-center mt-md-auto pt-4 pt-md-0 d-none d-md-flex">
              <Col md="12">
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label
                    className="minimal-title pe-3 mb-0"
                    htmlFor="group1"
                  >
                    Select Size
                  </Form.Label>
                  {data?.products?.hasAGuide && (
                    <Link to="/sizing-guide" className="text-white">
                      Sizing Guide
                    </Link>
                  )}
                </div>
                <hr className="w-100 mt-1" />
              </Col>
              <Col md="8">
                <Form.Group controlId="group1">
                  {data?.products?.variants?.map((product, index) => (
                    <Form.Check
                      key={index}
                      inline
                      label={product.name}
                      name="group1"
                      type="radio"
                      id={`inline-${index}`}
                      className={`btn btn-outline-light text-uppercase ${
                        data?.products?.isARingProduct ? "rings-selection" : ""
                      } ${selectedVariant === product.sku ? "active" : ""}`}
                      checked={selectedVariant === product.sku}
                      onChange={() => setSelectedVariant(product.sku)}
                    />
                  ))}
                </Form.Group>
              </Col>
              <Col md="4">
                <AnimationBorder>
                  <Button
                    className="btn btn-outline-light rounded gold-text w-100 mt-3 mt-md-0"
                    onClick={addItemToCart}
                  >
                    Add To Cart
                  </Button>
                </AnimationBorder>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      <PriceIncrease show={modalShow} onHide={() => setModalShow(false)} />
    </Layout>
  )
}

export default Product

export const query = graphql`
  query ($slug: String!) {
    products(slug: { eq: $slug }) {
      id
      title
      label
      features
      description
      hasInfoModal
      isARingProduct
      hasAGuide
      limitedEdition
      images
      variants {
        name
        cost
        features
        label
        sku
      }
    }
  }
`
