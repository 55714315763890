import React, { useState } from "react"
import { Carousel } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const ProductCarousel = ({ children }) => {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <Carousel
      indicators={false}
      activeIndex={index}
      onSelect={handleSelect}
      data-bs-animation="false"
    >
      <Carousel.Item>
        <StaticImage
          src="../images/ashur_pendant/insta-ashur-1.png"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="ashur pendant on male model"
          className="img-fluid rounded"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/ashur_pendant/insta-ashur-1_1.jpg"
          quality={95}
          height={636}
          formats={["AUTO", "WEBP"]}
          alt="ashur pendant on female model"
          className="img-fluid w-auto rounded"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/ashur_pendant/insta-ashur-2.png"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="ashur pendant front"
          className="img-fluid rounded"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/ashur_pendant/insta-ashur-3.png"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="ashur pendant back"
          className="img-fluid rounded"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/ashur_pendant/insta-ashur-4.png"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="ashur pendant medium size"
          className="img-fluid rounded"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/ashur_pendant/insta-ashur-5.png"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="ashur pendant small size"
          className="img-fluid rounded"
        />
      </Carousel.Item>
    </Carousel>
  )
}

export default ProductCarousel
