import * as React from "react"
import ProductCarousel from "./productCarousel"
import ProductCarouselLamassu from "./productCarouselLamassu"
import ProductCarouselAssyrianFlag from "./productCarouselAssyrianFlag"
import ProductCarouselTreeOLife from "./productCarouselTreeOLife"
import ProductCarouselRosette from "./productCarouselRosette"
import ProductCarouselAshurLapis from "./productCarouselAshurLapis"

const ShopPage = ({ product, children }) => {
  const renderView = sku => {
    switch (sku) {
      case "JW-FL22-SL01-LG-A01":
        return <ProductCarousel />

      case "JW-SP23-SL01-UI-B01":
        return <ProductCarouselLamassu />

      case "JW-SM23-SL01-UI-C01":
        return <ProductCarouselAssyrianFlag />

      case "JW-SM23-SL01-UI-C02":
        return <ProductCarouselRosette />

      case "JW-SM23-SL01-UI-C03":
        return <ProductCarouselTreeOLife />
        
      case "JW-SP24-SL01-UI-D01":
      case "JW-SP24-SL01-UI-D02":
      case "JW-SP24-SL01-UI-D03":
      case "JW-SP24-SL01-UI-D04":
      case "JW-SP24-SL01-UI-D05":
      case "JW-SP24-SL01-UI-D06":
      case "JW-SP24-SL01-UI-D07":
      case "JW-SP24-SL01-UI-D08":
      case "JW-SP24-SL01-UI-D09":
      case "JW-SP24-SL01-UI-D10":
        return <ProductCarouselAshurLapis />

      default:
        return <ProductCarousel />
    }
  }

  return renderView(product?.toUpperCase())
}

export default ShopPage
